import { Component, Input, OnInit } from '@angular/core';
import { SurveyService } from '../../../shared/services/survey.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-survey-questions',
  templateUrl: './survey-questions.component.html',
  styleUrls: ['./survey-questions.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class SurveyQuestionsComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  @Input() surveyURL:any='';
  @Input() showPreview:any;
  constructor(public surveyService:SurveyService,private spinner: NgxSpinnerService,
    public sanitizer: DomSanitizer, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const id= atob(params.id);
      if (id != '') {
        this.getSurveyDetails(id);
      }
    });    
  }

  getSurveyDetails(id:any){    
    this.spinner.show();
    this.surveyService.getSurveyDetails(id).subscribe((response: any) => {
      this.spinner.hide()
      console.log(response);
      let user: any = JSON.parse(localStorage.getItem('permission') || '{}').id;
      this.surveyURL =  this.sanitizer.bypassSecurityTrustResourceUrl( response?.url + `?user=${user}`);
    },err=>{
      this.spinner.hide()
    });
  }
  
  viewResults(){
    this.surveyService.setActiveTab('results');

  }
}
