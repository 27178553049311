<div class="row">
    <div class="col-sm-2">
        <div class="single-left-pane">
            <app-side-menu></app-side-menu>
        </div>
    </div>
    <div class="col-sm-7">
        <div class="single-center-pane" id="centerPaneD">
            <div class="row">
                <div class="col-md-12 col-12">
                    <!-- <mat-card class="matcorner "> -->
                        <div class="row" id="custom-height">
                            <div class="col-md-12">
                            
                                <div class="row mb-4">
                                    <div class="min-ht">
                                        <mat-tab-group cl mat-align-tabs="start" [selectedIndex]="tabIndex" (selectedTabChange)="onChangeTab($event)">
                                            <mat-tab label="Executive Summary" [ngClass]="{'active-tab': tabIndex==0}" >
                                                <app-detail-summary [totalSurveyList]="totalSurveyList" (surveyIdEmit)="loadSurveyResponse($event)" (loadData)="ngOnInit()"></app-detail-summary>
                                            </mat-tab>
                                            <!-- <mat-tab label="Detailed Responses" [ngClass]="{'active-tab': tabIndex==1}" >
                                                <app-question-view  [totalSurveyList]="totalSurveyList" (surveyIdByUserEmit)="loadSurveyResponseByUser($event)" (loadData)="ngOnInit()"></app-question-view>
                                            </mat-tab> -->
                                        </mat-tab-group>
                                    </div>
                                </div>
                                <!-- [options]="res.chart" -->

                                <!-- <highcharts-chart  style="display: flex;" [Highcharts]="Highcharts" [options]="surveyResponseNew" [callbackFunction]="chartCallback"  [oneToOne]=true></highcharts-chart> -->
                            </div>
                        </div>
                    <!-- </mat-card> -->
                </div>
                <!-- <div class="col-md-4 side-nav col-12">
                    <div class="row container-fluid p-0">
                        <app-list [list]="[]" [type]="'timeLineHelp'"></app-list>
                    </div>
                </div> -->
            </div>
        </div>
    </div>    
<div class="col-sm-3">
    <div class="single-right-pane">
        <span class="bubble-top" style="background-color: #ED0000;">&nbsp;</span>
        <h6>Insights & Engagement Zone</h6>
        <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button id="collapseOneButton" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style="font-size: 13px">
                    Executive Summary 
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <mat-card-subtitle class="mb-2 card-title-pi">{{surveyName}}</mat-card-subtitle>
                    <ng-container *ngFor="let res of totalSurveyResponse;">
                        <mat-card class="p-0 mb-2"  >
                            <mat-card-content class="p-1 card-color mb-0">
                                <mat-card-subtitle class="mb-1 okr-card-title">{{res?.display_text}} <mat-icon *ngIf="res.question_type != 'single_choice'" (click)="getSurveyCommentsByEachQuestion(res.id)" style="padding-top: 4px;font-size:14px;width:16px;height:16px">open_in_new</mat-icon></mat-card-subtitle>
                            </mat-card-content>
                            <mat-card-content class=" pt-1 text-center">
                                <div>
                                    <highcharts-chart  style="display: flex;" [Highcharts]="Highcharts" [options]="res.chart" [callbackFunction]="chartCallback"  [oneToOne]=true></highcharts-chart>
                                </div>
                            </mat-card-content>
                        </mat-card>
                        <mat-card>
                            <mat-card-subtitle *ngIf="res?.maxAvrageVendor" class="okr-card-title text-center" style="color:#000;font-weight: 500;font-size:12px">
                                <span *ngIf="surveyName == 'GDP-BVT Survey'"> {{getHighestField(res.maxAvrageVendor).option}} has the highest score for {{getHighestField(res.maxAvrageVendor).year}}.</span>
                                <span *ngIf="surveyName == 'IT BVT Survey'">  For {{getHighestField(res.maxAvrageVendor).year}}, the average score is {{getHighestField(res.maxAvrageVendor).avg}}. 
                                <span *ngIf="getHighestField(res.maxAvrageVendor).avg > 3">The OUs objectives are being enabled by the IT department.</span>
                                <span *ngIf="getHighestField(res.maxAvrageVendor).avg <= 3">More work required by the IT department to enable OUs.</span>
                                </span>
                            </mat-card-subtitle>
                        </mat-card><br>
                    </ng-container>
                    <div *ngIf="!totalSurveyResponse?.length" class="text-center">
                        <span class="fs-12">{{staticText.common.no_records_found}}</span>
                    </div>
                </div>
              </div>
            </div>
            <!-- <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button id="collapseTwoButton" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style="font-size: 13px">
                    Scoring By Question
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <mat-card-subtitle class="mb-2 card-title-pi text-center">{{qstSurveyName}} <span *ngIf="userName">-</span> {{userName}}
                    </mat-card-subtitle>
                    <ng-container *ngFor="let responds of questionAnswerSurvey;">
                        <mat-card class="p-0 mb-2"  *ngFor="let que of responds.questions;">
                            <mat-card-content class="p-1 card-color mb-0">
                                <mat-card-subtitle class="mb-1 okr-card-title">{{que?.display_text}}</mat-card-subtitle>
                            </mat-card-content>
                            <mat-card-content class="pb-2 pr-1 pl-1">
                                <ng-container *ngFor="let ans of que.answers;">
                                    <mat-card class="pt-0 mt-2 pb-0 pr-2 pl-2"  style="border-left: 6px solid #5ABAFA;">
                                        <mat-card-content>
                                                <div class="d-flex justify-content-between w-100" > 
                                                    <span class="mt-1">{{ans?.option_text}}</span>
                                                    <b class="fs-12 mt-1 pt-1 pb-1">{{ans?.answer_text}}</b>
                                                </div>
                                        </mat-card-content>
                                    </mat-card> 
                                    <div *ngIf="!que?.answers?.length" class="text-center">
                                        <span class="fs-12">{{staticText.common.no_records_found}}</span>
                                    </div>
                                </ng-container>
                            </mat-card-content>
                        </mat-card>
                    </ng-container>    

                    <div *ngIf="!questionAnswerSurvey?.length" class="text-center">
                        <span class="fs-12">{{staticText.common.no_records_found}}</span>
                    </div>
                </div>
              </div>
            </div> -->
          </div>
    </div>
</div>