import { Component, ElementRef, OnInit, ViewChild, EventEmitter,Output, Input  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SurveyService } from '../../../shared/services/survey.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PowerBiService } from '../../../shared/services/power-bi.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
import { IReportEmbedConfiguration, models, Page, Report, service, Embed } from 'powerbi-client';


@Component({
  selector: 'app-detail-summary',
  templateUrl: './detail-summary.component.html',
  styleUrls: ['./detail-summary.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class DetailSummaryComponent implements OnInit {
  @Input() totalSurveyList:any;
  @Output() surveyIdEmit = new EventEmitter();
  @Output() loadData = new EventEmitter<string>();
  staticText: any = (textConfiguration as any).default;
  @ViewChild('iframe') iframe: any | ElementRef<HTMLInputElement>;
  checked: boolean;
  barChartData:any
  surveyId:any='';
  surveyName:any;
  enableEachSubOption:boolean = false;
  surveyData:any;
  surveyDetailsByEachSubOption:any;
  surveyDetailsBySubotionCategory:any
  eachSubOption:any;
  subOptionByCategories:any;
  singleChoiceQuestionEachOption:any;
  singleChoiceQuestionQuestionOptionCategory:any
  surveyOptions:any = [];
  stores:any=[];
  reportClass = 'report-container';
  optionType:any;
  surveySummary:any;
  reportModel:IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  }; 
  reportConfig = this.reportModel;
  reportConfigSUnBurst = this.reportModel;
  sentimentGraph = this.reportModel;
  reportConfigRadarChart = this.reportModel;
  rightPanelBiReport:any;
  zoomLevel:any;  
  
  constructor(private surveyService:SurveyService,private PowerBiService : PowerBiService,private spinner: NgxSpinnerService,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.surveyId = atob(params.id);
    });
    
  }
 
  emitSurveyEvent(surveyId:any,surveyName:any,surveyGraphId:any){
    this.surveyName = surveyName;
    this.loadSurveyData(surveyId,surveyName);
    this.loadPowerBIReport(surveyId,surveyName,surveyGraphId);
    this.loadSurveySummary(surveyId);
    this.surveyIdEmit.emit({surveyId:surveyId,surveyName:surveyName});
    
  }
  refresh(id: any){
    this.spinner.show();
    this.surveyService.refreshData(id).subscribe((res: any) => {
      this.loadData.emit()
      this.spinner.hide();
    })
  }
  loadSurveySummary(surveyId:any){
    this.surveyService.getSurveySummary(surveyId).subscribe((res: any) => {
      this.surveySummary = res;
    });
  }

  loadSurveyData(surveyId:any,surveyName:any){
    this.spinner.show();
    this.surveyOptions = [];
    this.surveyService.loadSurveyById(surveyId).subscribe((res: any) => {
      this.spinner.hide();
      this.surveyData = res;
      this.surveyData.forEach((elem:any) => {
        if(elem?.question_type != 'single_choice'){
          elem?.options.forEach((elm:any,key:any)=>{
            this.surveyOptions.push({id:elm?.option?.id,display_text:elm?.option?.display_text});
          })
        }
      });
      this.surveyOptions = this.surveyOptions
                          .map((item:any) => item.display_text)
                          .filter((value:any, index:any, self:any) => self.indexOf(value) === index);
                          this.optionType = this.surveyOptions[0];
       this.loadQuestionByOption();
    })
  }
  clickOptions(option:any){
    this.optionType = option;
    this.loadQuestionByOption();
  }

  loadQuestionByOption(){
    this.surveyDetailsByEachSubOption = [];
    this.surveyDetailsBySubotionCategory = [];
    this.singleChoiceQuestionEachOption = [];
    this.singleChoiceQuestionQuestionOptionCategory = [];
    this.surveyData.forEach((elem:any) => {
    var eachSubOption:any = [];
    var subOptionByCategories:any = [];
    var singleChoiceEachOption:any = [];
    var singleChoiceOptionByCategories:any = [];
        if(elem?.question_type == 'single_choice'){
          singleChoiceEachOption.push({options:elem?.options})
          singleChoiceOptionByCategories.push({positive:elem?.options[0]?.percentage ? Number(elem?.options[0]?.percentage) : 0  +  elem?.options[1]?.percentage ? Number(elem?.options[1]?.percentage) : 0,
                                              neutral:elem?.options[2]?.percentage ? Number(elem?.options[2]?.percentage) : 0,
                                              negative:elem?.options[3]?.percentage ? Number(elem?.options[3]?.percentage) : 0  + elem?.options[4]?.percentage ? Number(elem?.options[4]?.percentage) : 0 + elem?.options[5]?.percentage ? Number(elem?.options[5]?.percentage) : 0 })
        }else{
          elem?.options.forEach((option:any) => {
            if(option?.option?.display_text == this.optionType){
              eachSubOption.push({option:option?.option?.display_text,sub_options:option?.sub_options})
              subOptionByCategories.push({option:option?.option?.display_text,positive:Number(option?.sub_options[0]?.sub_option_percentage) + Number(option?.sub_options[1]?.sub_option_percentage),neutral:Number(option?.sub_options[2]?.sub_option_percentage),negative:Number(option?.sub_options[3]?.sub_option_percentage) + Number(option?.sub_options[4]?.sub_option_percentage) + Number(option?.sub_options[5]?.sub_option_percentage)})
            }
          });
        }
        if(eachSubOption?.length > 0){
          this.surveyDetailsByEachSubOption.push({display_text:elem?.display_text,eachSubOption});
          this.surveyDetailsBySubotionCategory.push({display_text:elem?.display_text,subOptionByCategories});
        }
        if(singleChoiceEachOption?.length > 0){
          this.singleChoiceQuestionEachOption.push({display_text:elem?.display_text,singleChoiceEachOption});
          this.singleChoiceQuestionQuestionOptionCategory.push({display_text:elem?.display_text,singleChoiceOptionByCategories})
        }
    });
  }

  loadPowerBIReport(surveyId:any,surveyName:any,surveyGraphId:any){
    this.reportConfig = this.reportModel;
    this.reportConfigSUnBurst = this.reportModel;
    this.sentimentGraph = this.reportModel;
    this.reportConfigRadarChart  = this.reportModel;
    this.setBIZoomLevel();
    var basicFilter:any = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
          table: "survey_data",
          column: "survey_id"
      },
      operator: "In",
      values:[`${surveyName}`],
      filterType: models.FilterType.Basic
  };
    this.PowerBiService.getTokenForBI().subscribe((res: any) => {
      this.PowerBiService.getBIEmbedURL(res.access_token,'85b9dc7e-920c-4e3f-ae08-381fc6790615').subscribe((reportData: any) => {
        this.PowerBiService.getBIEmbedToken(res.access_token,'85b9dc7e-920c-4e3f-ae08-381fc6790615','c226f7c6-3ae1-4d6f-b68b-8bc5338cee43').subscribe((tokenData: any) => {
          this.reportConfig = this.loadPowerBIReportGraph(reportData,tokenData,basicFilter,'ReportSection');
          this.reportConfigSUnBurst = this.loadPowerBIReportGraph(reportData,tokenData,basicFilter,'ReportSection8af2a03d8fe018756739');
          this.sentimentGraph = this.loadPowerBIReportGraph(reportData,tokenData,basicFilter,'ReportSectiondfab39e5901145ac5474');
          this.reportConfigRadarChart =  this.loadPowerBIReportGraph(reportData,tokenData,basicFilter,'ReportSectione96a1efafb3b93e6ab9d');
        })
      });
    });
  }
  loadPowerBIReportGraph(reportData:any,tokenData:any,basicFilter:any,pageName:any){
   return {type: 'report',
    id: reportData.id,
    embedUrl: reportData.embedUrl,
    accessToken:tokenData.token,
    tokenType: models.TokenType.Embed,
    pageName: pageName,
     filters: [basicFilter],
    settings: {
        panes: {
            filters: {
                expanded: false,
                visible: false
            },
            pageNavigation: {
              visible: false
            },
        },
        zoomLevel :this.zoomLevel,
        background: models.BackgroundType.Transparent,
      }
    }
  }
  setBIZoomLevel(){
    if(window.innerWidth > 1500){
      this.zoomLevel = 0.6;
    }else if(window.innerWidth <= 1500 && window.innerWidth > 1246 ){
      this.zoomLevel = 0.5;
    }else if(window.innerWidth <= 1245 ){
      this.zoomLevel = 0.4
    }
  }
}