<div class="p-2 pt-4">
    <div class="custom-table fixed-table-header">
        <table class="table table-bordered">
            <thead style="position: sticky;top: 0; z-index: 1">
                <tr>
                    <th class="fixed-header" scope="col">#</th>
                    <th class="fixed-header" scope="col" class="w-25">Survey</th>
                    <th class="fixed-header" scope="col">Start Date</th>
                    <th class="fixed-header" scope="col">End Date</th>
                    <th class="fixed-header" scope="col">Respondants</th>
                    <th class="fixed-header text-center col-2" scope="col">Check Response</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of totalSurveyList; let i = index">
                    <tr>
                        <td class="align-middle" [attr.rowspan]="item.respondents.length+1">{{i+1}}</td>
                        <td class="align-middle" [attr.rowspan]="item.respondents.length+1">{{item.name}}</td>
                        <td class="align-middle" [attr.rowspan]="item.respondents.length+1">{{item.start_date}}</td>
                        <td class="align-middle" [attr.rowspan]="item.respondents.length+1">{{item.end_date}}</td>
                    </tr>
                    <tr *ngFor="let user of item.respondents; let i = index">
                        <td class="align-middle text-center">{{user.user.first_name}} {{user.user.last_name}}</td>
                        <td class="align-middle text-center">
                            <a class="add-btton" style="justify-content: center;"
                            (click)="emitSurveyEventByUser(item.survey_id,user.id,item.name,user.user.first_name,user.user.last_name)">
                                <mat-icon aria-hidden="false" aria-label="material-icons-filled" class="cursor-pointer fs-5">exit_to_app</mat-icon>
                            </a>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>   
    </div>
</div>