<div class="w-100 bg-lavendar mt-80">
    <app-header></app-header>
</div>
<div class="p-3">
    <div class="px-0 pb-4">
        <app-bread-crumb></app-bread-crumb>
    </div>
    <router-outlet></router-outlet>
</div>
<app-footer></app-footer>

<!-- <div class="w-100 bg-lavendar mt-80">
    <app-header></app-header>
</div>

<div class="d-flex flex-column" class="header1">
    <div class="bg-cont-overlay">
        <div class="container-fluid container-xxl">
            <div class="row">
                <div class="col"></div>
                <div class="col">
                    <div class="text-right">
                        <ul class="m-0 p-0 fs-12 breadcrumb-bs">
                            <li><span routerLink="/home" class="color-torquise cursor-pointer">{{staticText.menu.home}}</span></li>
                            <li><span routerLink="/BVT/list" class="color-torquise cursor-pointer">{{staticText.survey.bvt_survey}}</span></li>
                            <li><span class="text-white">{{staticText.survey.survey_list}}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>

<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="text-muted">
                    <p align="center">Copyright © {{currentYear}} {{staticText.footer.text}}</p>
                </div>
            </div>
        </div>
    </div>
</footer> -->
