<div>
    <div class="row">
        <div class="col pt-3 d-flex ml-3 mr-3">
            <p class="fw-bold empall-border">
                <span class="px-3">{{staticText.survey.heading}}</span>
            </p>                       
        </div>
    </div>
    <div >
        <!-- <div class="tb-head ml-3 mr-3">{{data.key}}</div> -->
        <div *ngIf="!summaryData?.summary || summaryData?.summary?.length==0" class="no-dta"> {{staticText.common.no_results_found}}</div>
        <table class="table table-bordered" *ngIf="summaryData?.summary">
           <ng-container *ngFor="let data of summaryData?.summary | keyvalue;let first=first">
            <tr class="row-hide" *ngIf="!first"><td attr.colspan="{{organizationList.length +2}}" ></td></tr>
                <tr class="tb-head fw-bold"><td attr.colspan="{{organizationList.length +2}}" >{{data.key | replaceCharacter:"_":" "}}</td></tr>
                <tr *ngIf="first">
                    <td class="table-primary bgcolor-first fw-bold">{{staticText.survey.question}}</td>
                    <td *ngFor="let item of organizationList;let i=index;" class="table-secondary fw-bold" [ngStyle]="{'backgroundColor': i|getRandomColor:'table_colors'}" >{{item | uppercase}}</td>
                    <td class="table-primary bgcolor-average fw-bold">{{staticText.survey.average}}</td>
                  </tr>
                <tr *ngFor="let item of data?.value?.result">
                    <td  class="table-secondary bgcolor-first fw-bold qndata px-4" matTooltip="{{item?.question | replaceCharacter:'_':' '}}"  matTooltipClass="example-tooltip">{{item?.question | replaceCharacter:"_":" " }}</td>
                    <td *ngFor="let res of organizationList;let i=index" class="table-secondary fw-medium" [ngClass]="{'color-low': (item?.score?.[organizationList[i]] |number)<3 }">{{item?.score?.[organizationList[i]]}}</td>                    
                    <td  class="table-secondary bgcolor-last color-black fw-bold">{{item?.avg}}</td>
                </tr>
                <tr>
                    <td  class="table-secondary bgcolor-avg fw-bold fw-bold">{{staticText.survey.average}}</td>
                    <td *ngFor="let item of organizationList;let i=index" class="table-secondary bgcolor-first fw-bold" >{{data?.value?.vendor_avg?.[organizationList[i]] ??0}}</td>                    
                    <td  class="table-secondary bgcolor-final fw-bold">{{data?.value?.questions_avg}}</td>
                </tr>                
            </ng-container>
            <tr class="row-hide"><td attr.colspan="{{organizationList.length +2}}" ></td></tr>
            <tr *ngIf="organizationList.length">
                <td  class="table-secondary bgcolor-avg fw-bold">{{staticText.survey.survey_avg}}</td>
                <td *ngFor="let item of organizationList;let i=index" class="table-secondary bgcolor-first fw-bold" >{{summaryData?.survey_average?.[organizationList[i]] ??0}}</td>                    
                <td  class="table-secondary bgcolor-final fw-bold">{{summaryData?.survey_question_average}}</td>
            </tr>
            <tr *ngIf="organizationList.length">
                <td  class="table-secondary bgcolor-avg fw-bold">{{staticText.survey.score_below}} </td>
                <td *ngFor="let item of organizationList;let i=index;" class="table-secondary bgcolor-first fw-bold" >{{ summaryData?.scoring_percent?.[organizationList[i]] ??0}}</td>                    
                <td  class="table-secondary bgcolor-final fw-bold">{{summaryData?.question_scoring_percent}}</td>
            </tr>
        </table>
    </div>
</div>
