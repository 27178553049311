import { Component, OnInit } from '@angular/core';
import { ProgramService } from 'src/app/shared/services/program.service';
import { GetProposalService } from 'src/app/shared/services/get-proposal.service';
import { ToastrService } from 'ngx-toastr';
import {  Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { InnovationService } from '../../../shared/services/innovation.service';
import { SurveyService } from '../../../shared/services/survey.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { DataToCsvService } from 'src/app/shared/services/data-to-csv.service';
import { DatePipe } from '@angular/common';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-survey-list',
  templateUrl: './survey-list.component.html',
  styleUrls: ['./survey-list.component.scss'],
  encapsulation:ViewEncapsulation.Emulated,
  providers:[DatePipe]
})
export class SurveyListComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;

  unPublishedSurveyList: any[] = [];
  publishedSurveyList: any[] = [];
  limit: any;
  offset: any;
  totalPublishedCount: any;
  totalUnpublishedCount: any;
  searchText = '';
  search = false;
  filterForm: FormGroup | any;
  projectList: any[] = [];
  subPortfilteredOptions:any
  portFolioId: any;
  projectId: any;
  portList:any =[];
  subPortId: any;
  page: number = 1;
  endVal: number = 12;
  startVal: number = 0;
  itemsPerPage: number = 12;
  subPortChildOptions: any[] = [];
  OpportunityFieldList: any;
  disableShowMore: boolean = false;
  totalSurveyList:any=[];
  scree_name = 'Survey';
  vendor: any;
  vendorList: any;

  constructor(private proposalService: GetProposalService,
    private toastrService: ToastrService,
    private router: Router,
    private fb: FormBuilder,
    private ps: ProgramService,
    private spinner: NgxSpinnerService,    
    private csvService: DataToCsvService,private datePipe:DatePipe,
    private innovationService: InnovationService,private surveyService:SurveyService) { }

  ngOnInit(): void {
    this.limit = 10;
    this.offset = 0;
    this.getDataSource();
    this.surveyService.getPermissions();
  }

  getDataSource() {
    this.spinner.show();
    this.surveyService.getSurveyList().subscribe((res: any) => {
      this.spinner.hide();
      this.totalSurveyList = res?.records;
      this.publishedSurveyList = res?.records;
      this.totalPublishedCount = this.publishedSurveyList.length;
      this.spinner.hide();
    }, (error: any) => { }, () => {
      this.spinner.hide();
    })
  }

  getOpportunity() {
    this.ps.getOpportunityFormData('new_opportunity').subscribe((res: any) => {
      this.OpportunityFieldList = res.records;
      this.OpportunityFieldList.map((i: any) => {
        if (i.field_name == "opportunity_portfolio") {
          this.portList = i.field_values;
        }
      })
      this.createForm();
    }, error => {
      throw error;
    })
  }
  getProjectList(){    
    this.innovationService.projectList().subscribe((res: any) => {
      this.projectList = res;
    });
  }

  clearFilter() {
    this.searchText = '';
    this.portFolioId = 0;
    this.subPortId = 0;
    this.filterForm.patchValue({
      portfolio: '',
      subPortfolio: '',
    })
    this.getDataSource();
  }

  deleteProposal(id: any) {
    this.proposalService.deleteProposal(id).subscribe((res: any) => {
      this.getDataSource();
      this.toastrService.success('Deleted Successfully');
    }, (error: any) => {
      this.toastrService.error('Error');
      throw error;
    })
  }
  editProposal(id: any) {
    this.router.navigate([`rfx/`+btoa(id)+`/opportunity/setup`]);
  }

  createForm() {
    this.filterForm = this.fb.group({
      portfolio: [''],
      subPortfolio: [''],
    });
  }
  selectedPortfolioValues(id: any) {
    this.portFolioId = id;
    this.subPortfilteredOptions = [];
    this.subPortId = "";
    // if (!this.portFolioId) {
    //   return;
    // }
    this.filterForm.patchValue({
      subPortfolio: ''
    })
    this.getDataSource();
    this.subPortfilteredOptions = [];
  }

  selectedSubportfolioValues(id: any) {
    this.subPortId = id;
    this.getDataSource();
  }

  private subPortfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.subPortfilteredOptions.filter((i: any) => i.display_name.toLowerCase().includes(filterValue));
  }

  changeSubPortfolio(e: any) {
    if (e.isUserInput) {
      this.subPortId = e.source.value.id;
      this.getDataSource();
    }
  }

  viewProposal(id: any) {
    this.router.navigate([`getproposal/${btoa(id)}/proposal-evaluation`])
  }

  onChangeTable(page: any) {
    this.page = page;
    this.startVal = (page - 1) * (this.itemsPerPage);
    this.endVal = this.startVal + (this.itemsPerPage);
    this.getDataSource();
  }

  searchName() {
    this.getDataSource();
  }
  onInputChange(e: any) {
    if (this.searchText == '') {
      this.getDataSource();
    }
  }

  callRefereshParentData(e: any) {
    this.getDataSource()
  }
  showAllUnpublishedProposals() {
    this.startVal = 0;
    this.endVal = this.unPublishedSurveyList?.length + (this.itemsPerPage);
    this.getDataSource();
    if (this.totalUnpublishedCount < this.endVal) {
      this.disableShowMore = true;
    }
  }
  showAllPublishedProposals() {
    this.startVal = 0;
    this.endVal = this.unPublishedSurveyList?.length + (this.itemsPerPage);
    this.getDataSource();
    if (this.totalUnpublishedCount < this.endVal) {
      this.disableShowMore = true;
    }
  }

  getStatus(status: string) {
    return 'red';
  }

  surveyPage() {    
    window.open(this.surveyService.surveyUrl,'_blank')
  }

  projectChange(e: any) {
    this.projectId = e;
    this.getDataSource();
    this.showAllPublishedProposals();
    this.showAllUnpublishedProposals();
  }
  selectedGDP(id: any) {
    this.getDataSource();
  }
  
  getGDP() {
    this.surveyService.getVendorList().subscribe((res: any) => {
      if (res) {
        this.vendorList = res.records[0].field_values;        
      }
    });
  }

  saveAsCSV() {
    if(this.totalSurveyList && this.totalSurveyList.length > 0){
      const items:any = [];
      console.log(this.totalSurveyList);      
      this.totalSurveyList.forEach((item:any) => {
        let csvData = {
          "Survey form ID": item.formid,
          "Survey Title": item.title,
          "Description": item.description,
          "No. of Submissions":item.num_of_submissions,
          "Created on" : this.datePipe.transform(item?.date_created,'dd MMM yyyy'),
          "Updated on" :this.datePipe.transform(item?.date_modified,'dd MMM yyyy'),
          "Survey Start Date" :this.datePipe.transform(item?.survey_start_date,'dd MMM yyyy'),
          "Survey End Date" :this.datePipe.transform(item?.survey_end_date,'dd MMM yyyy'),
          "Survey Status":item.survey_published ? 'Published':'Unpublished',
        }
        items.push(csvData); 
      });
      this.csvService.exportToCsv('Survey_List.CSV', items);
    }else{
      this.toastrService.error('No Records to download');
    }
  }

  viewResults(){
    this.surveyService.setActiveTab('results');
    this.router.navigate([`BVT/survey-details/results`])

  }
}