import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SurveyService } from '../../../shared/services/survey.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import _ from 'lodash';
import { ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-overall-summary',
  templateUrl: './overall-summary.component.html',
  styleUrls: ['./overall-summary.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class OverallSummaryComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  surveyId:any;
  questionsList:any = []
  organizationList:any = []
  summaryData:any={};
  constructor(private surveyService:SurveyService,private spinner: NgxSpinnerService,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.surveyId = atob(params.id);
    });
    this.getData()
  }

  getData(){    
    this.spinner.show();
    this.surveyService.getOverallSummary(this.surveyId).subscribe((response: any) => {
      this.spinner.hide()
      if(response){
        this.summaryData = response;
        this.organizationList = Object.keys(response['survey_average'])
        Object.keys(response['summary']).forEach(element => {
          let category =response['summary'][element];
          let result:any = [];
          Object.keys(category).forEach(qn => {
            let resultObj:any={ "question":qn,"score":category[qn],"avg":""} 
            if(typeof category[qn] == typeof {}){
              let oKeys = Object.keys(category[qn]);
              const lowercased = oKeys.map(name => name.toLowerCase());
              if(lowercased.includes("scoring")){
                Object.keys(category[qn]).forEach((qnobj:any) => { 
                  if(qnobj?.toLowerCase()?.includes("scoring")){                  
                    resultObj["score"] = _.transform(category[qn][qnobj], (result:any, val, key:any)=> {result[key.toLowerCase()] = val});
                  }
                  else if(qnobj== "question_average"){
                    resultObj["avg"] = category[qn][qnobj]
                  }
                });
              }else{
                resultObj["avg"] = category[qn]["question_average"];
                delete category[qn]["question_average"];
                resultObj["score"] = _.transform(category[qn], (result:any, val, key:any)=> {result[key.toLowerCase()] = val});                
              }                
            }
            result.push(resultObj);
          });
          let keyname = element?.replace(/\s+/g,"_");
          let vendorAvgname:any = Object.keys(response['vendor_average']).find((name:any)=> keyname.includes(name));
          let qnAvgname:any = Object.keys(response['questions_average']).find((name:any)=> keyname.includes(name));
          let summaryObj={"result":result,"vendor_avg":response['vendor_average'][vendorAvgname],"questions_avg":response["questions_average"][qnAvgname]};
          this.summaryData['summary'][element] =summaryObj;
        });
        console.log(this.summaryData);
        
      }        
    },err=>{
      this.spinner.hide()
    });
  }
}
