<div class="row">
    <div class="col-sm-2">
        <div class="single-left-pane">
            <app-side-menu></app-side-menu>
        </div>
    </div>
    <div class="col-sm-7">
        <div class="single-center-pane">
            <div class="row" *ngIf="surveyURL">
                <div class="col-md-12 col-12">
                    <iframe [src]="surveyURL" frameborder='0' style='height:700px;width:100%; border-radius: 15px;'
                        marginwidth='0' marginheight='0' scrolling='auto' allow='geolocation'></iframe>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3">
        <div class="single-right-pane">
            <span class="bubble-top" style="background-color: #ED0000;">&nbsp;</span>
            <h6>Insights & Engagement Zone</h6>
            <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"
                            style="font-size: 13px">
                            Opportunity Heat Map by Tags
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div class="comingSoon text-center">
                                <img src="assets/images/analytics_bg.png">
                                <h6 style=" margin-top: 21px; ">Coming Soon !</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>