import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { SurveyService } from '../../../shared/services/survey.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';

@Component({
  selector: 'app-survey-responses',
  templateUrl: './survey-responses.component.html',
  styleUrls: ['./survey-responses.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class SurveyResponsesComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  surveyForm:FormGroup|any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  //tags:any=[];
  tagCtrl = new FormControl();  
  searchCtrl = new FormControl('');
  surveyId:any;
  distributorList:any =[]
  filterOptions: Observable<any[]> | any;
  isSubmitted = false;
  selectedRespondents:any={};

  
  questionsList:any = []
  headingList = []
  organizationSurveyResult:any = []
  avgData:any=[]
  constructor(private surveyService:SurveyService,private spinner: NgxSpinnerService,private activatedRoute:ActivatedRoute,private toast:ToastrService,private _formValidator:FormValidatorService) { 
    this.surveyForm = new FormGroup({
      respondents: new FormControl('',[Validators.required,this._formValidator.autocompleteObjectValidator]),
    })
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.surveyId = atob(params.id);
      //this.getResponses(this.surveyId);
    });
    this.isSubmitted = this.surveyService?.formData?.num_of_submissions > 0
    this.getDistributorsList();
  }
  getResponses(opt:any){    
    this.selectedRespondents =opt.option?.value;    
    this.isSubmitted = true;
    let userdata:any = localStorage.getItem("permission")
    userdata = JSON.parse(userdata)
    if(!opt.option?.value?.mail ) return;
    let payload={"username": opt.option?.value?.mail };//userdata.username
    this.spinner.show();    
    this.surveyService.getResponses(this.surveyId,payload).subscribe((response: any) => {
      this.spinner.hide()
      //let response:any = {"Overall":{"How_do_you_rate_your_overall_s":{"Scoring":{"cts":"1","hex":"2","mt":"3","tcs":"4"}},"Overall_have_you_seen_GDP_imp":{"Scoring":{"cts":"2","hex":"3","mt":"4","tcs":"5"}},"Which_recent_GDP_actions_have_":{"CTS":"4","HEX":"5","MT":"3","TCS":"2"},"What_are_the_top_3_areas_for_i":{"CTS":"2","HEX":"3","MT":"4","TCS":"4"}},"STRATEGIC_PARTNERSHIP":{"How_do_you_rate_GDP_in_underst":{"Scoring":{"cts":"2","hex":"3","mt":"3","tcs":"4"}},"How_easy_is_GDP_to_do_business":{"Scoring":{"cts":"4","hex":"5","mt":"5","tcs":"4"}},"To_what_extent_does_GDP_ensure":{"Scoring":{"cts":"2","hex":"4","mt":"5","tcs":"6"}},"Does_GDP_provide_you_with_suff":{"Scoring":{"cts":"3","hex":"4","mt":"4","tcs":"5"}}}}
    this.questionsList=[]
    this.organizationSurveyResult=[]
    this.avgData = []
    Object.keys(response).forEach(element => {
      if(typeof response[element] !== typeof {}){
        let ob:any={"company":element,"score":[ response[element]]}
        this.organizationSurveyResult=[ob]
      }else{
        Object.keys(response[element]).forEach((qn:any) => {
          this.questionsList.push(qn)        
          let que= response[element][qn]
          let scoreqn={}
          if(Object.keys(que).length == 1){  
            let key1=Object.keys(que)[0]
            scoreqn =que[key1]         
          }else{
            scoreqn= que;
          } 
          var lowerObj = _.transform(scoreqn, (result:any, val, key:any)=> {
            result[key.toLowerCase()] = val;
          });
          this.organizationSurveyResult.push(lowerObj)
          let arr:any = Object.values(lowerObj)
          const average:any = arr.reduce((a:any, b:any) => Number(a) + Number(b)) / arr.length;
          this.avgData.push(average);
        });
      }
    });
    const arr = this.organizationSurveyResult;
    if(arr.length>0 && this.questionsList.length>0){
      this.organizationSurveyResult=[];
      Object.keys(arr[0]).forEach((o:any)=>{
        let obj:any={}
        obj['company'] = o.toUpperCase();
        obj['score'] = arr.map((d:any)=> { return d[o]; });
        this.organizationSurveyResult.push(obj)
      })
    }
    console.log(this.organizationSurveyResult);
    },err=>{
      this.spinner.hide()
      this.questionsList=[]
      this.organizationSurveyResult=[]
      this.avgData = []    
      this.toast.error("No matching responses found")
    });
    
  }
  getDistributorsList(){
    this.spinner.show();
    this.surveyService.getDistributors().subscribe((response: any) => {
      this.spinner.hide()
      console.log(response);
      this.distributorList =response['records'].find((i: any) => i.field_name == "opportunity_team").field_values;      
      this.filterOptions = this.surveyForm.get('respondents')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.name),
          map((name: any) => name ? this._filter(name) : this.distributorList.slice())
        );
    },err=>{
      this.spinner.hide()
    });
  }  
  _filter(value: string): any {
    //this.selectedRespondents='';
    const filterValue = value.toLowerCase();
    return this.distributorList.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }  
  // addTag(event: MatChipInputEvent|any,searchInput:any): void {
  //   const value = event.option.value || ''
  //   if (value) {
  //     this.tags.push(value);
  //   }
  //   let res= this.tags.map((o:any)=>o.name);
  //   this.selectedRespondents = res.join(", ")
  //   searchInput.value = '';
  //  this.searchCtrl.setValue(null);
  //  this.distributorList = this.distributorList.filter((o:any) => o != value);
  //  this.distributorList = this.distributorList ? this.distributorList :[]
  //  this.filterOptions = this.filterOptions.pipe(filter((o:any)=> o !=value));
  // }
  // removeTag(tag: any): void {
  //   const index = this.tags.indexOf(tag);
  //   if (index >= 0) {
  //     this.tags.splice(index, 1);
  //     this.distributorList.push(tag) 
  //     this.filterOptions = this.filterOptions.pipe(filter((o:any)=> o !=tag));
  //   }
  // }
  viewResults(){
    this.surveyService.setActiveTab('results');
  }
  displayFn(data: any) {
    return data && data.name ? data.name : '';
  }
}
