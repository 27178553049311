import { Component, OnInit } from '@angular/core';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  currentYear = new Date().getFullYear();
  staticText: any = (textConfiguration as any).default;
  constructor() { }

  ngOnInit(): void {
  }

}
