import { Component, OnInit,ChangeDetectorRef,Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SurveyService } from '../../../shared/services/survey.service';
import { NgxSpinnerService } from 'ngx-spinner';

import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { barChartOptions } from 'src/app/shared/helpers/barChartOptions';
declare var require: any;
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
// let Boost = require('highcharts/modules/boost');
// let noData = require('highcharts/modules/no-data-to-display');
// let More = require('highcharts/highcharts-more');
// let threeD = require('highcharts/highcharts-3d')
// Boost(Highcharts);
// noData(Highcharts);
// More(Highcharts);
// noData(Highcharts);
// threeD(Highcharts);

@Component({
  selector: 'app-question-view',
  templateUrl: './question-view.component.html',
  styleUrls: ['./question-view.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class QuestionViewComponent implements OnInit {
  @Input() totalSurveyList:any;
  @Output() surveyIdByUserEmit = new EventEmitter();
  staticText: any = (textConfiguration as any).default;
  surveyId:any;
  scoringData:any=[];
   chartOptions: {[key:string]:any} = {}; 
   chartData: {[key:string]:any} = {};
  constructor(private surveyService:SurveyService,private spinner: NgxSpinnerService,private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.surveyId = atob(params.id);
    });
  }
 
  emitSurveyEventByUser(surveyId:any,userId:any,surveyName:any,userFirstName:any,userLastName:any){
    this.surveyIdByUserEmit.emit({surveyId:surveyId,userId:userId,surveyName:surveyName,userName:userFirstName+' '+userLastName})
  }
}
