import { Component, OnInit } from '@angular/core';
import { SurveyService } from '../../../shared/services/survey.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-survey-details',
  templateUrl: './survey-details.component.html',
  styleUrls: ['./survey-details.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class SurveyDetailsComponent implements OnInit {
enketoURL:any = this.sanitizer.bypassSecurityTrustResourceUrl('');

surveyURL: any = this.sanitizer.bypassSecurityTrustResourceUrl('');
showTab$: Observable<string> | any;
showPreview = false;
  constructor(private surveyService:SurveyService,private spinner: NgxSpinnerService,private activatedRoute:ActivatedRoute,public sanitizer: DomSanitizer ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const id= atob(params.id);
      if (id != '') {
        this.surveyService.getActiveTab().subscribe((res:any)=>{
          this.showTab$ = res;
          if(this.showTab$ == 'questions'){
             this.getSurveyDetails(id);
           }else if(this.showTab$ == 'preview'){
            this.showPreview=true;
             this.getPreviewUrl(id);
          }
        });
      }
    });    
    
  }
  changeTab(page:string){
    this.showTab$ = page
  }
  getSurveyDetails(id:any){    
    this.spinner.show();
    this.surveyService.getSurveyDetails(id).subscribe((response: any) => {
      this.spinner.hide()
      console.log(response);
      this.surveyURL =  this.sanitizer.bypassSecurityTrustResourceUrl( response?.url);
    },err=>{
      this.spinner.hide()
    });
  }
  getEnketoUrl(id:any){
    this.spinner.show();
    this.surveyService.getEnketoUrl(id).subscribe((response: any) => {
      this.spinner.hide()
      console.log(response);
      this.enketoURL = this.sanitizer.bypassSecurityTrustResourceUrl( response?.enketo_url);
    },err=>{
      this.spinner.hide()
    });
  }
  
  getPreviewUrl(id:any){
    this.spinner.show();
    this.surveyService.getPreviewUrl(id).subscribe((response: any) => {
      this.spinner.hide()
      console.log(response);
      this.enketoURL = this.sanitizer.bypassSecurityTrustResourceUrl( response?.enketo_url);
    },err=>{
      this.spinner.hide()
    });
  }
}
