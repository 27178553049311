import { Component, OnInit } from '@angular/core';
import { SurveyService } from '../../../shared/services/survey.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';

const noData = require('highcharts/modules/no-data-to-display')
noData(Highcharts)

@Component({
  selector: 'app-survey-results',
  templateUrl: './survey-results.component.html',
  styleUrls: ['./survey-results.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class SurveyResultsComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  tabIndex:any=0;
  allBarCharts:any
  showPreview=false
  totalSurveyResponse:any;
  questionAnswerSurvey:any;
  Highcharts: typeof Highcharts = Highcharts;
  totalSurveyList:any;
  surveyName:any;
  surveyId:any;
  qstSurveyName:any;
  userName:any;

  surveyResponseNew: any;
  reportClass = 'right-panel-bi-class';
 
 
  constructor(public surveyService:SurveyService,
    private spinner: NgxSpinnerService,private dialog: MatDialog,
    ) { }

  ngOnInit(): void {
    const perm = JSON.parse(localStorage.getItem('permission') || '{}');
    let permission = perm.permissions;
      if(permission.indexOf("nps.add_survey")>-1){
        this.surveyService.setActiveTab('questions');
        this.showPreview =true;
        this.getSurevyList();
      }
    
  }

  onChangeTab(evt:any){
  }
  previewSurvey(){
    this.surveyService.setActiveTab('preview');
  }

  getSurevyList(){
    this.spinner.show();
    this.surveyService.getSurveyList().subscribe((response: any) => {
      this.spinner.hide();
      this.totalSurveyList = response?.records?.open_surveys;
    });
  }

  loadSurveyResponse(event:any){
    this.spinner.show();
    this.surveyName = event.surveyName;
    this.surveyId = event.surveyId;
    this.totalSurveyResponse = [];
    this.surveyService.getSurveyResponseById(event.surveyId).subscribe((response: any) => {
      this.accordionTask('one');
      this.spinner.hide();

      // this.surveyResponseNew = this.barchartNew(response);
      // let objDiv: any = document.getElementById("centerPaneD");
      // objDiv.scrollTop = objDiv.scrollHeight;
      var maxAvrageVendor:any = [];   
        
      response.forEach((res:any) => {
        var options:any = [];
        var datas:any = [];
        var avrages:any = [];
        maxAvrageVendor = [];
        res.options.forEach((opt:any)=>{
          options.push(opt?.option?.display_text);
          opt?.stats.forEach((val:any)=>{
            const index = avrages.findIndex((x:any) => x.name === val?.year);
            if(index >= 0){
              avrages[index]?.data?.push(Number(val?.average));
            }else{
              avrages.push({'type': 'column',name:val?.year,data:[Number(val?.average)], dataLabels: {enabled: true}});
            }
            maxAvrageVendor.push({'option':opt?.option?.display_text,avg:Number(val?.average),year:val?.year});
          });
        })
        var res:any = {display_text:res.display_text,chart:this.barchartReturn(res.display_text,options,avrages),id:res.id,question_type:res.question_type,maxAvrageVendor}
        this.totalSurveyResponse.push(res);  
      });
    });
  }

loadSurveyResponseByUser(event:any){
  this.spinner.show();
  this.qstSurveyName = event.surveyName;
  this.userName = event.userName;
  this.questionAnswerSurvey = [];
  this.surveyService.getQuestionAnswers(event).subscribe((response: any) => {
   this.questionAnswerSurvey = response.survey_responses;
   this.accordionTask('two');
    this.spinner.hide();
  });
}
barchartReturn(question:any,options:any,values:any){
return { chart: {
    type: "category",
    height:180,
    
  },
  title: {
    text: ""
  },
  tooltip: {
    shared: true
  },
  xAxis: {
    scrollbar: {
      enabled: false
    },
    categories: options
  },
  series: values,
  dataLabels: {
           enabled: true,
  },
  // series: [
  //   {
  //     'type': 'column',
  //     data: values,
  //     dataLabels: {
  //       enabled: true,
  //      // rotation: -90,
  //       color: '#00000',
  //       align: 'right',
  //       y: 10, // 10 pixels down from the top
  //       style: {
  //           fontSize: '10px',
  //        //   fontFamily: 'helvetica, arial, sans-serif',
  //           textShadow: false,
  //           fontWeight: 'normal'

  //       }
  //   }
  //   }
  // ],
  exporting: { enabled: false },
  credits: {
    enabled: false
  },
  legend: { enabled: true },
  yAxis: {
    title: false
 }
};
}

barchartNew(res: any){
  let categories: any = [];
  let series: any = [];
  res.forEach((q: any) => {
    if(q.options.length > 0){
      categories.push(q.display_text);

      q.options.forEach((opt: any) => {
        if(series.findIndex((e: any) => e.name == opt.option.display_text) < 0){
          series.push({
            name: opt.option.display_text,
            data: []
          })
        }
      });  
    }
  });

  res.forEach((q: any) => {
    if(q.options.length > 0){
      q.options.forEach((opt: any) => {
        series[series.findIndex((e: any) => e.name == opt.option.display_text)].data.push(opt.average)
      });
    }
  })
  
  return {
    chart: {
        type: 'column'
    },
    title: {
        text:  this.surveyName
    },
    xAxis: {
        categories: categories,
        crosshair: false
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Score'
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    credits: {
      enabled: false
    },
    series: series
  }
}
accordionTask(type:any){
  var collapseOne = document.getElementById('collapseOne');
  var collapseOneButton = document.getElementById('collapseOneButton');
  var collapseTwo = document.getElementById('collapseTwo');
  var collapseTwoButton = document.getElementById('collapseTwoButton');
  if(type=='one'){
    collapseOne?.classList.remove('collapse');
    collapseOneButton?.classList.remove('collapsed');
    collapseTwo?.classList.add('collapse');
    collapseTwoButton?.classList.add('collapsed')
  }
  if(type=='two'){
    collapseOne?.classList.add('collapse');
    collapseOneButton?.classList.add('collapsed');
    collapseTwo?.classList.remove('collapse');
    collapseTwoButton?.classList.remove('collapsed')
  }
}
chartCallback: Highcharts.ChartCallbackFunction = function (chart): void {
  setTimeout(() => {
      chart.reflow();
  },0);
}     

getSurveyCommentsByEachQuestion(qstId:any){
  this.spinner.show();
  this.surveyService.getSurveyCommentsByQuestion(this.surveyId,qstId).subscribe((response: any) => {
    const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true });
    dialog.componentInstance.type = {
      "name":"surveyComments",
      "comments":response,
      "id":qstId
    };
    this.spinner.hide();
  });
}

  getHighestField(data:any){
   return data.reduce(function(prev:any, current:any) {
    if (+current.avg > +prev.avg) {
        return current;
    } else {
        return prev;
    }
  });
 }
}
