<div class="p-2 pt-4">
    <div class="custom-table fixed-table-header">
        <table class="table table-bordered">
            <thead style="position: sticky;top: 0; z-index: 1">
                <tr>
                    <th class="fixed-header" scope="col">#</th>
                    <th class="fixed-header" scope="col" class="w-25">Survey</th>
                    <th class="fixed-header" scope="col">Start Date</th>
                    <th class="fixed-header" scope="col">End Date</th>
                    <th class="fixed-header" scope="col">Response Count</th>
                    <th class="fixed-header text-center col-2" scope="col">Check Response</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of totalSurveyList; let i = index">
                    <td class="align-middle">{{i+1}}</td>
                    <td class="align-middle">{{item.name}}</td>
                    <td class="align-middle">{{item.start_date}}</td>
                    <td class="align-middle">{{item.end_date}}</td>
                    <td class="align-middle text-center">{{item.response_count}}</td>
                    <td class="align-middle text-center">
                        <a class="add-btton" style="justify-content: center;"
                        (click)="emitSurveyEvent(item.id,item.name,item.survey_id)">
                            <mat-icon aria-hidden="false" aria-label="material-icons-filled" class="cursor-pointer fs-5">exit_to_app</mat-icon>
                        </a>

                        <span class="material-icons refre" (click)="refresh(item.id)">refresh</span>
                    </td>
                </tr>
            </tbody>
        </table>   
    </div>
</div>


<div *ngIf="surveyName" class="text-center pt-2 pb-2">
    <h4 class="fw-bold">{{surveyName}}</h4>
    <h6>{{surveySummary?.response_count}} of {{surveySummary?.respondent_count}} Survey Recipients Responded, Constituting a {{(surveySummary?.response_count/surveySummary?.respondent_count)*100 | number : '1.0-0'}}%  Response Rate.</h6>
    <div *ngIf="surveyName == 'GDP-BVT Survey'" >    
        <div *ngIf="surveySummary?.vendor_average?.length > 0" >
            <!-- Top 3 GDPs Based on Average Score are -<br> -->
            <!-- <ol start="1" style="display: inline-block;"> -->
                <ng-container *ngFor="let vendor of surveySummary?.vendor_average;index as i" >
                    <span style="font-size: 14px;font-weight: bold;" *ngIf="i < 3">{{vendor?.vendor}} ({{vendor?.average | number : '1.2-2'}})<span *ngIf="i<2" >, </span> </span>
                </ng-container> are the top three GDPs based on performance.<br>
            <!-- </ol><br> -->
            <!-- Bottom 3 GDPs Based on Average Score are -<br> -->
            <!-- <ol start="1" style="display: inline-block;"> -->
                <ng-container *ngFor="let vendor of surveySummary?.vendor_average;index as i;">
                    <span style="font-size: 14px;font-weight: bold;" *ngIf="i > surveySummary?.vendor_average?.length - 4">{{vendor?.vendor}} ({{vendor?.average | number : '1.2-2'}})<span *ngIf="i > surveySummary?.vendor_average?.length-4 && i != surveySummary?.vendor_average?.length -1" >, </span>  </span>
                </ng-container> are the bottom three GDPs based on performance. 
            <!-- </ol> -->
        </div>
        <div *ngIf="surveySummary?.page_average?.length > 0">
            <!-- Sections with GDPs Average Scores are - <br> -->
            <div >
                <div *ngFor="let page of surveySummary?.page_average;index as i;" class="mt-2">
                    Within section <span style="font-size: 14px;font-weight: bold;">{{page?.page?.title}}, </span>
                    <!-- <ol style="display: inline-block;"> -->
                        <ng-container  *ngFor="let vendor of page?.vendor_average;index as i">
                            <span style="font-size: 14px;" *ngIf="i < 3">{{vendor?.vendor}} ({{vendor?.average | number : '1.2-2'}})<span *ngIf="i<2" >, </span>  </span>
                        </ng-container> are the best performers.<br>

                        Within section <span style="font-size: 14px;font-weight: bold;">{{page?.page?.title}}, </span>
                        <ng-container  *ngFor="let vendor of page?.vendor_average;index as i">
                            <span style="font-size: 14px;" *ngIf="i > surveySummary?.vendor_average?.length - 4">{{vendor?.vendor}} ({{vendor?.average | number : '1.2-2'}})<span *ngIf="i > surveySummary?.vendor_average?.length-4 && i != surveySummary?.vendor_average?.length -1" >, </span>  </span>
                        </ng-container> are the worst performers.<br>
                    <!-- </ol> -->
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="surveyName == 'IT BVT Survey'" style="font-size: 12px;">
       <b> Delta delivers technology solutions with appropriate value for the financial cost?, IT embodies “Keep Climbing” - I see measurable progress in IT's performance from this time last year?, IT enables my business strategies and goals?</b>
       were the questions with the best performance having avg scores <b>5, 5, 4</b> respectively.<br>
        <b>IT is a “partner of choice” for you (attributes including partnership, transparency, responsiveness and core Delta values)?, IT enables my business to manage IT-dependent operational risk effectively (PCI, SOX, Cyber, etc.)?, IT delivers a reliable production environment that enables me to run my business effectively?  </b>
        were the questions with the weak performance having avg scores <b>3, 3, 4</b> respectively.
    </div>    
</div>

<div *ngIf="surveyName">
    <div class="row pb-2">
        <div style="background-color: #E5E4E2;">
            <h5 class="fw-bold pt-4 pl-4 pb-0">Comparison of Avg. Scores for Each Question</h5>
            <powerbi-report [embedConfig]="reportConfig" [cssClassName]="reportClass"></powerbi-report>
        </div>
    </div>            
</div>



<div class="row pb-2 pt-2" *ngIf="surveyData?.length > 0">
    <div class="container p-4" style="background-color: #E5E4E2;">
        <div class="p-4">
            <div class="d-flex justify-content-between">
                <div>
                    <h5 class="fw-bold">% of Respondents for Each Question Spilt for Each Score</h5>
                    <div class="d-flex justify-content-end">
                        <div *ngFor="let option of surveyOptions">
                            <div class="mr-2 cursor-pointer" (click)="clickOptions(option)" style="font-size:12px" [class.optinSelected] = "option === optionType"><mat-icon style="font-size: 6px;width:10px;">lens</mat-icon>{{option}}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <span class="fs-8">Sentiment Analysis</span>
                    <div class="toggle-container">
                        <input type="checkbox" id="toggle-button-checkbox"
                        (change)="enableEachSubOption=$event.target.checked">
                        <label class="toggle-button-switch"  
                            for="toggle-button-checkbox"></label>
                        <div class="toggle-button-text">
                            <div class="toggle-button-text-on">ON</div>
                            <div class="toggle-button-text-off">OFF</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fixed-table-header mt-4">
                <table *ngIf="!enableEachSubOption">
                    <tr class="custom-tr">
                        <th class="custom-th"></th>
                        <th class="custom-th">Exceptional</th>
                        <th class="custom-th">Good</th>
                        <th class="custom-th">As Expected</th>
                        <th class="custom-th">Below Average</th>
                        <th class="custom-th">Under Performing</th>
                        <th class="custom-th">Regressing</th>
                    </tr>
                    <ng-container *ngFor="let survey of surveyDetailsByEachSubOption">
                        <tr class="cutom-tr">
                            <td class="w-75">
                                <div class="d-flex justify-content-start align-items-center">
                                    <div class="pr-2">
                                        <mat-icon class="receipt">receipt</mat-icon>   
                                    </div>
                                    <div class="w-75 text-display">{{survey?.display_text}}</div> 
                                </div>
                            </td>
                            <ng-container *ngFor="let option of survey.eachSubOption">
                                <ng-container *ngFor="let sub_option of option.sub_options">
                                    <td class="custom-td" >
                                        <div class="flex-wrapper">
                                            <div class="single-chart">
                                            <svg viewBox="0 0 36 36" class="circular-chart orange">
                                                <path class="circle-bg"
                                                d="M18 2.0845
                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                                />
                                                <path class="circle"
                                                attr.stroke-dasharray="{{sub_option.sub_option_percentage ? sub_option.sub_option_percentage : 0}}, 100"
                                                d="M18 2.0845
                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                                />
                                                <text x="18" y="20.35" class="percentage">{{sub_option.sub_option_percentage ? sub_option.sub_option_percentage : 0}}%</text>
                                            </svg>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>
                    </ng-container>
                    <ng-container *ngFor="let survey of singleChoiceQuestionEachOption">
                        <tr class="cutom-tr">
                            <td class="w-75">
                                <div class="d-flex justify-content-start align-items-center">
                                    <div class="pr-2">
                                        <mat-icon class="receipt">receipt</mat-icon>   
                                    </div>
                                    <div class="w-75 text-display">{{survey?.display_text}}</div> 
                                </div>
                            </td>
                            <ng-container *ngFor="let option of survey.singleChoiceEachOption">
                                <ng-container *ngFor="let sub_option of option.options">
                                    <td class="custom-td" >
                                        <div class="flex-wrapper">
                                            <div class="single-chart">
                                            <svg viewBox="0 0 36 36" class="circular-chart orange">
                                                <path class="circle-bg"
                                                d="M18 2.0845
                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                                />
                                                <path class="circle"
                                                attr.stroke-dasharray="{{sub_option.percentage ? sub_option.percentage : 0}}, 100"
                                                d="M18 2.0845
                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                                />
                                                <text x="18" y="20.35" class="percentage">{{sub_option.percentage ? sub_option.percentage : 0}}%</text>
                                            </svg>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>
                    </ng-container>
                </table>
                <table *ngIf="enableEachSubOption">
                    <tr class="custom-tr">
                        <th class="custom-th w-50"></th>
                        <th class="custom-th text-left ml-4 pl-3">Positive</th>
                        <th class="custom-th text-left ml-4 pl-3">Neutral</th>
                        <th class="custom-th text-left ml-4 pl-3">Negative</th>
                    </tr>
                    <ng-container *ngFor="let survey of surveyDetailsBySubotionCategory">
                        <tr class="cutom-tr">
                            <td class="w-50">
                                <div class="d-flex justify-content-start align-items-center">
                                    <div class="pr-2">
                                        <mat-icon class="receipt">receipt</mat-icon>   
                                    </div>
                                    <div class="w-75 text-display">{{survey?.display_text}}</div> 
                                </div>
                            </td>
                            <ng-container *ngFor="let option of survey.subOptionByCategories">
                                    <td class="custom-td" >
                                        <div class="flex-wrapper">
                                            <div class="single-chart">
                                            <svg viewBox="0 0 36 36" class="circular-chart orange">
                                                <path class="circle-bg"
                                                d="M18 2.0845
                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                                />
                                                <path class="circle"
                                                attr.stroke-dasharray="{{option.positive ? option.positive : 0}}, 100"
                                                d="M18 2.0845
                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                                />
                                                <text x="18" y="20.35" class="percentage">{{option.positive ? option.positive : 0}}%</text>
                                            </svg>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="custom-td" >
                                        <div class="flex-wrapper">
                                            <div class="single-chart">
                                            <svg viewBox="0 0 36 36" class="circular-chart orange">
                                                <path class="circle-bg"
                                                d="M18 2.0845
                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                                />
                                                <path class="circle"
                                                attr.stroke-dasharray="{{option.neutral ? option.neutral : 0}}, 100"
                                                d="M18 2.0845
                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                                />
                                                <text x="18" y="20.35" class="percentage">{{option.neutral ? option.neutral : 0}}%</text>
                                            </svg>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="custom-td" >
                                        <div class="flex-wrapper">
                                            <div class="single-chart">
                                            <svg viewBox="0 0 36 36" class="circular-chart orange">
                                                <path class="circle-bg"
                                                d="M18 2.0845
                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                                />
                                                <path class="circle"
                                                attr.stroke-dasharray="{{option.negative ? option.negative : 0}}, 100"
                                                d="M18 2.0845
                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                                />
                                                <text x="18" y="20.35" class="percentage">{{option.negative ? option.negative : 0}}%</text>
                                            </svg>
                                            </div>
                                        </div>
                                    </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                    <ng-container *ngFor="let survey of singleChoiceQuestionQuestionOptionCategory">
                        <tr class="cutom-tr">
                            <td class="w-50">
                                <div class="d-flex justify-content-start align-items-center">
                                    <div class="pr-2">
                                        <mat-icon class="receipt">receipt</mat-icon>   
                                    </div>
                                    <div class="w-75 text-display">{{survey?.display_text}}</div> 
                                </div>
                            </td>
                            <ng-container *ngFor="let option of survey.singleChoiceOptionByCategories">
                                    <td class="custom-td" >
                                        <div class="flex-wrapper">
                                            <div class="single-chart">
                                            <svg viewBox="0 0 36 36" class="circular-chart orange">
                                                <path class="circle-bg"
                                                d="M18 2.0845
                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                                />
                                                <path class="circle"
                                                attr.stroke-dasharray="{{option.positive ? option.positive : 0}}, 100"
                                                d="M18 2.0845
                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                                />
                                                <text x="18" y="20.35" class="percentage">{{option.positive ? option.positive : 0}}%</text>
                                            </svg>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="custom-td" >
                                        <div class="flex-wrapper">
                                            <div class="single-chart">
                                            <svg viewBox="0 0 36 36" class="circular-chart orange">
                                                <path class="circle-bg"
                                                d="M18 2.0845
                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                                />
                                                <path class="circle"
                                                attr.stroke-dasharray="{{option.neutral ? option.neutral : 0}}, 100"
                                                d="M18 2.0845
                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                                />
                                                <text x="18" y="20.35" class="percentage">{{option.neutral ? option.neutral : 0}}%</text>
                                            </svg>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="custom-td" >
                                        <div class="flex-wrapper">
                                            <div class="single-chart">
                                            <svg viewBox="0 0 36 36" class="circular-chart orange">
                                                <path class="circle-bg"
                                                d="M18 2.0845
                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                                />
                                                <path class="circle"
                                                attr.stroke-dasharray="{{option.negative ? option.negative : 0}}, 100"
                                                d="M18 2.0845
                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                                />
                                                <text x="18" y="20.35" class="percentage">{{option.negative ? option.negative : 0}}%</text>
                                            </svg>
                                            </div>
                                        </div>
                                    </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                 </table>
            </div>
        </div>
    </div>
</div>

<div *ngIf="surveyName">
    <div class="row pt-2 pb-2">
        <div style="background-color: #E5E4E2;">
            <h5 class="fw-bold pt-4 pl-4 pb-0">Section-wise and Vendor-wise Comparison of Avg. Scores</h5>
            <powerbi-report [embedConfig]="reportConfigRadarChart" [cssClassName]="reportClass"></powerbi-report>
        </div>
    </div>
    <div class="row pt-2 pb-2">
        <div style="background-color: #E5E4E2;">
            <h5 class="fw-bold pt-4 pl-4 pb-0">Comparison of Sentiment Score and Avg. Score for Each Question</h5>
            <powerbi-report [embedConfig]="sentimentGraph" [cssClassName]="reportClass"></powerbi-report>
        </div>
    </div>
    <div class="row pt-2 pb-2">
        <div style="background-color: #E5E4E2;">
            <h5 class="fw-bold pt-4 pl-4 pb-0">Comparison of Avg. Scores for Portfolios and Sub Portfolios </h5>
            <powerbi-report [embedConfig]="reportConfigSUnBurst" [cssClassName]="reportClass"></powerbi-report>
        </div>
    </div>
</div>
