<div class="container-fluid proposal container-xxl">
    <div class="custom-height" id="custom-height">
        <div class="row">
            <div class="col-md-12">
                <div class="screen-style">
                    <div class="row mb-4">
                        <div class="col-md-7">
                            <div class="pt-4 mb-4">
                                <!-- <app-bread-crumb></app-bread-crumb> -->
                                <h2 class="screen-name mt-1">{{staticText.survey.survey_list}}</h2>
                            </div>
                        </div>
                        <div class="col-md-5 pr-1" *ngxPermissionsOnly="['logger.add_publish']">
                            <div class="d-flex justify-content-end  pt-2">
                                <button class="float-end border primary lightSecondary add-idea-btn ml-3" mat-button
                                    (click)="surveyPage()" mat-flat-button>
                                    {{staticText.survey.create_survey}}
                                </button>

                                <button class="float-end border primary lightSecondary add-idea-btn ml-3" mat-button
                                    (click)="viewResults()" mat-flat-button>
                                    {{staticText.survey.view_results}}
                                </button>
                                
                                <button mat-button class="float-end border primary lightSecondary download-btn ml-3" *ngIf="totalSurveyList && totalSurveyList?.length>0"
                                    data-toggle="tooltip" data-placement="top" title="Export as CSV" (click)="saveAsCSV()" mat-flat-button>Download</button>
                            </div>
                        </div>
                        <div class="col-md-12 p-0">
                            <div class="container-fluid px-2">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <p class="count text-dark pt-3">{{staticText.survey.published_surveys}} ({{totalPublishedCount}})</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <app-table-card [proposalLists]="publishedSurveyList" [screen_name]="scree_name"
                                    (refereshParentData)="callRefereshParentData($event)"></app-table-card>
                            </div>
                        </div>
                        <div *ngIf="!disableShowMore && totalPublishedCount > 12" class="show-more-wrapper">
                            <a class="show-more fw-bold" (click)="showAllPublishedProposals()">{{staticText.common.show_more}}<i
                                    class="fas fa-angle-double-down fw-normal m-1"></i></a>
                        </div>
                        <!-- <ng-container *ngxPermissionsOnly="['logger.add_publish']">
                            <div class="col-md-12 p-0">
                                <div class="container-fluid px-2">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>
                                            <p class="count text-dark pt-3">{{staticText.survey.unpublished_surveys}} ({{totalUnpublishedCount}})
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <app-table-card [proposalLists]="unPublishedSurveyList" [screen_name]="scree_name"
                                        (refereshParentData)="callRefereshParentData($event)"></app-table-card>
                                </div>
                            </div>
                            <div *ngIf="!disableShowMore && totalUnpublishedCount > 12" class="show-more-wrapper">
                                <a class="show-more fw-bold" (click)="showAllUnpublishedProposals()">{{staticText.common.show_more}}<i
                                        class="fas fa-angle-double-down fw-normal m-1"></i></a>
                            </div>
                            </ng-container> -->
                    </div>
                </div>
            </div>
        </div>