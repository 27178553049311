<div class="row pt-4">
    <div class="col-md-12 col-12">
        <mat-card class="matcorner ">
            <div class="row" id="custom-height">
                <div class="col-md-12">
                    <div class="row">
                        <div>
                            <!-- <app-bread-crumb></app-bread-crumb> -->
                            <p class="heading-color fw-500 mb-0">{{staticText.survey.responses}}</p>
                        </div>
                    </div>
                    <hr>
                    <form [formGroup]="surveyForm" novalidate>
                        <div class="row">
                            <mat-label class="color-bl">{{staticText.survey.select_distributors}}* </mat-label>
                            <mat-form-field class="example-chip-list col-6" appearance="outline">                              
                               <input matInput [matAutocomplete]="autoRes" type="text"
                                   formControlName="respondents">
                               <mat-icon matSuffix class="muted">search</mat-icon>
                               <mat-autocomplete #autoRes="matAutocomplete"
                                   [displayWith]="displayFn" (optionSelected)="getResponses($event)">
                                   <mat-option *ngFor="let tag of filterOptions | async "
                                       [value]="tag">
                                       {{tag.name}}
                                   </mat-option>
                                   <mat-error *ngIf=" surveyForm.get('respondents').touched && surveyForm.get('respondents').errors">                                    
                                    <small class="text-danger" *ngIf="surveyForm.get('respondents').errors.optionError">{{staticText?.common?.autocomplete_option_error}}</small>
                                </mat-error>
                               </mat-autocomplete>
                                <!-- <mat-chip-list #chipList aria-label="Fruit selection" formControlName="respondents">
                                    <mat-chip *ngFor="let tag of tags" (removed)="removeTag(tag)">
                                        {{tag.name}}
                                        <button matChipRemove>
                                            <mat-icon>cancel</mat-icon>
                                        </button>
                                    </mat-chip>
                                    <input #searchInput [formControl]="searchCtrl"
                                        [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        (matChipInputTokenEnd)="addTag($event,searchInput)">
                                </mat-chip-list>
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addTag($event,searchInput)">
                                    <mat-option *ngFor="let tag of filterOptions | async " [value]="tag">
                                        {{tag.name}}
                                    </mat-option>
                                </mat-autocomplete> -->
                            </mat-form-field>
                        </div>
                        <div>
                            <div class="row" *ngIf="!this.surveyForm.get('respondents').errors && this.surveyForm.get('respondents').value">
                                <div class="col pt-3 d-flex">
                                    <p class="fw-bold empall-border namedept">
                                        <span class="px-3 pb-2">{{this.surveyForm.get('respondents').value?.name}}</span>
                                        <span class="mx-3 dept">{{this.surveyForm.get('respondents').value?.department}}</span>
                                    </p>               
                                </div>
                            </div>
                            <div>
                                <table class="table table-bordered table-responsive" *ngIf="questionsList.length>0">
                                    <thead>
                                        <tr>
                                            <td class="table-primary border-0 bg-qn fw-bold">{{staticText.survey.question}}</td>
                                            <td *ngFor="let item of questionsList;let i=index;" class="table-secondary qndata bgcolor-first" matTooltip="{{item | replaceCharacter:'_':' '}}"  matTooltipClass="example-tooltip" >{{item | replaceCharacter:"_":" " | slice:0:10}}...</td>
                                          </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of organizationSurveyResult;let cindex=index">
                                            <td  class="table-secondary fw-bold px-3" [ngStyle]="{'backgroundColor': cindex|getRandomColor:'table_colors'}">{{item?.company}}</td>
                                            <td *ngFor="let res of item.score" class="table-secondary fw-medium" [ngClass]="{'bgcolor-low': (res |number)<3 ,'bg-neutral':(res |number)==3}">{{res}}</td>                    
                                        </tr>
                                        <tr>
                                            <td  class="table-secondary border-0 bgcolor-avg fw-bold">{{staticText.survey.avg}}</td>
                                            <td *ngFor="let res of avgData" class="table-secondary bgcolor-avgval fw-bold">{{res}}</td>                    
                                        </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="isSubmitted && (!questionsList || questionsList.length==0)" class="text-center fs-18 fw-500 mt-4">{{staticText.survey.no_response}}</div>
                            </div>
                        </div>
                        <!-- <mat-card-actions class="border-top mb-0 py-3 pb-0 foot-position  footer-align">
                            <div class="row">
                                <div class="col-md-6 text-right"></div>
                                <div class="col-md-6 text-right">
                                    <button mat-button class="next-color text-white fs-16" type="submit">
                                        <span class="m-0">
                                            Next
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </mat-card-actions> -->
                    </form>
                </div>
            </div>
        </mat-card>
    </div>
    <!-- <div class="col-md-4 side-nav col-12">
        <div class="row container-fluid p-0">
            <app-list [list]="[]" [type]="'timeLineHelp'"></app-list>
        </div>
    </div> -->
</div>