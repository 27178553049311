import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatOptionModule } from '@angular/material/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { MaterialmodulesModule } from 'src/app/shared/materialmodules.module';
import { SurveyListComponent } from './survey-list/survey-list.component';
import { PublishSurveyComponent } from './publish-survey/publish-survey.component';
import { SurveyQuestionsComponent } from './survey-questions/survey-questions.component';
import { SurveyResponsesComponent } from './survey-responses/survey-responses.component';
import { SurveyResultsComponent } from './survey-results/survey-results.component';
import { SurveyDetailsComponent } from './survey-details/survey-details.component';
import { IndexComponent } from './index/index.component';
import { OverallSummaryComponent } from './overall-summary/overall-summary.component';
import { DetailSummaryComponent } from './detail-summary/detail-summary.component';
import { QuestionViewComponent } from './question-view/question-view.component';
import { ChartModule } from 'angular-highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PowerBIEmbedModule } from 'powerbi-client-angular';

const routes: Routes = [
  {
    path: '',
    data:{breadcrumb:'Hubble'},
    component: IndexComponent,
    children: [
      {
        data: {breadcrumb:'Survey'},
        path: 'take-survey/:id',
        component: SurveyQuestionsComponent,
      },
      {
        data: {breadcrumb:'Results'},
        path: 'survey-details/:id',
        component: SurveyResultsComponent,
      },
      {
        path: 'list',data:{breadcrumb:'Survey List'},
        component: SurveyListComponent,
      }

    ]
  }];

@NgModule({
  declarations: [
    SurveyListComponent,
    PublishSurveyComponent,
    SurveyQuestionsComponent,
    SurveyResponsesComponent,
    SurveyResultsComponent,
    IndexComponent,
    SurveyDetailsComponent,
    OverallSummaryComponent,
    DetailSummaryComponent,
    QuestionViewComponent
  ],
  imports: [
    CommonModule,SharedModule,RouterModule.forChild(routes),
    SharedModule,
    MatIconModule,
    FormsModule, ReactiveFormsModule,
    ChartModule,
    HighchartsChartModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    NgxDatatableModule,
    MatMenuModule,
    MatButtonModule,
    MatOptionModule,
    NgxPaginationModule,
    MaterialmodulesModule,
    PowerBIEmbedModule,
    NgxPermissionsModule.forChild()
  ]
})
export class SurveyModule { }
